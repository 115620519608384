import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../common/component';
import { AppState } from '../app.state';
import { IdentityService } from '../common/service/IdentityService';
import { SalesValidationService } from '../common/service';
import { Router } from '@angular/router';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
  login: Boolean;
  menu: [];
  table1 = false;
  jobStatus = [];
  loading = true;
  // jobStatus = [ {'no':'Validation 36825','initials':'TS','username':'SharmaTA','time':'Job is waiting on lookup data refresh','job_failure':'true','job_success':'false'}, {'no':'Validation 36824','initials':'TS','username':'SMITHT11','time':'Aug 25 2020 @ 08:45:35 AM for 2 sec','job_failure':'true','job_success':'false'}, {'no':'Validation 36823','initials':'AI','username':'InamdaA','time':'Aug 25 2020 @ 07:45:35 AM for 2 sec','job_failure':'true','job_success':'false'}, {'no':'Validation 36822','initials':'TS','username':'SMITHT11','time':'Aug 24 2020 @ 09:45:35 AM for 2 sec','job_failure':'true','job_success':'false'},{'no':'Validation 36821','initials':'SY','username':'EBIZ','time':'Aug 24 2020 @ 08:45:35 AM for 2 sec','job_failure':'true','job_success':'false'}, {'no':'Validation 36820','initials':'AI','username':'InamdaA','time':'Aug 24 2020 @ 07:45:35 AM for 2 sec','job_failure':'true','job_success':'false'},{'no':'Validation 36819','initials':'TS','username':'SMITHT11','time':'Aug 23 2020 @ 09:45:35 AM for 2 sec','job_failure':'true','job_success':'false'}, {'no':'Validation 36818','initials':'TS','username':'SharmaTA','time':'Aug 23 2020 @ 08:45:35 AM for 2 sec','job_failure':'true','job_success':'false'},{'no':'Validation 36817','initials':'AI','username':'InamdaA','time':'Aug 23 2020 @ 07:45:35 AM for 2 sec','job_failure':'true','job_success':'false'}, {'no':'Validation 36816','initials':'SY','username':'EBIZ','time':'Aug 22 2020 @ 09:45:35 AM for 2 sec','job_failure':'true','job_success':'false'}]
  constructor(public service: IdentityService,public translate: TranslateService,public state: AppState, public service1: SalesValidationService, public router: Router) { 
    super();
    this.state.changeBreadcrumbs([{ name: 'eBiz', link: '' }]);
  }

  ngOnInit(): void {
    this.translate.get('menu').subscribe(data => {
      this.menu = data;
    });

    this.login = this.service.loggedIn;
    if(!this.login) {
      let interval = setInterval( () => {
        this.login = this.service.loggedIn;
        if(this.login && this.state.get('ACCESS_TOKEN')!=null) {
          this.getValidationSet();
          clearInterval(interval);
        }
      }, 1000);
    } else {
      // this.state.set('ACCESS_TOKEN',null);
      let interval2 = setInterval( () => {
        if(this.state.get('ACCESS_TOKEN')!=null) {
          this.getValidationSet();
          clearInterval(interval2);
        }
      }, 1000);
    }
  }

  getValidationSet() {
    let colors = [0];
    let temp = 0;
    let lookup = {"EBIZ": '3'};
    this.service1.getValidationSets().subscribe( data => {
      this.jobStatus = data['data'];
      for(let i of this.jobStatus) {
        i['runLength'] = (new Date(i['endTime']).getTime() - new Date(i['startTime']).getTime())/1000;
        if(lookup[i['createdById']]==undefined) {
          if(i['createdById']!='EBIZ'){
            lookup[i['createdById']] = colors[temp];
            if(temp == 2) {
              temp = 0;
            } else {
              colors.push(colors[temp]+1);
              temp += 1;
            }
          }
        }
        i['initialsColor'] = lookup[i['createdById']];
        if(i['createdById'] == 'EBIZ') {
          i['initials'] = 'SY';
        } else {
          let s = '';
          for(let j=i['createdById'].length-1;j>=0;j--) {
            if(i['createdById'].charCodeAt(j)>=65 && i['createdById'].charCodeAt(j)<=90) {
              s += i['createdById'][j];
              break;
            }
          }
          s += i['createdById'][0];
          i['initials'] = s;
        }
      }
      // console.log(this.jobStatus);
    }, error => {
      this.loading = false;
    });
  }

  showValidationDetails(cred, id) {
    this.state.set('Credentials', cred);
    this.router.navigate(['current', 'validationSet', `${id}`]);
  }

  downloadExcel(option) {
    let header = [];

    if(option === 'sales') {
      header = ['DIVISION_CODE', 'REPORTING_LOCATION_ID', 'WAREHOUSE_ID', 'INVOICE_DATE', 'INVOICE_NUMBER', 'ARTICLE_NO', 'QUANTITY', 'UOM', 'SHIP_TO_CUSTOMER_TYPE', 'SHIP_TO_CUSTOMER_ID', 'SHIP_NAME', 'SHIP_ADDR', 'SHIP_ADDR2', 'SHIP_CITY', 'SHIP_STATE', 'SHIP_ZIP', 'BILL_TO_CUSTOMER_TYPE', 'BILL_TO_CUSTOMER_ID', 'BILL_NAME', 'BILL_ADDR', 'BILL_ADDR2', 'BILL_CITY', 'BILL_STATE', 'BILL_ZIP', 'POS_KEY', 'REPLOC_TYPE'];
    } else if(option === 'inventory') {
      header = ['DIVISION_CODE', 'REPORTING_LOCATION_ID', 'WAREHOUSE_ID', 'INVENTORY_REPORT_DATE', 'ARTICLE_NO', 'QUANTITY_ON_HAND', 'UNIT_OF_MEASURE_TYPE'];
    } else if(option === 'end_inventory') {
      header = ['REPORTING_LOCATION_ID', 'WAREHOUSE_ID', 'ARTICLE_NO', 'INVENTORY_QTY', 'UNIT_OF_MEASURE_TYPE', 'SEASON_ID', 'BUSINESS_SEGMENT_CD'];
    } else if(option === 'demographics_sales') {
      header = ['Rec ID','Rep Loc ID', 'Rep Loc Type', 'Trans Date', 'Prod Trans Type', 'Invoice No', 'Invoice Qual', 'Article No', 'Qty', 'Qty Deb Cred Type', 'UOM', 'Ship To Cust ID', 'Ship To Cust Type', 'Ship To Name', 'Ship To Addr', 'Ship To Addr 2', 'Ship To City', 'Ship To State', 'Ship To Zip', 'Ship To BASF ID', 'Bill To Cust ID', 'Bill To Name', 'Bill To Addr', 'Bill To Addr 2', 'Bill To City', 'Bill To State', 'Bill To Zip', 'Bill To BASF ID', 'WH ID', 'WH Name', 'WH Addr', 'WH City', 'WH State', 'WH Zip', 'Distributor Sales Rep', 'Order Date'];
    }

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(option);
  
    const headerRow = worksheet.addRow(header);

    worksheet.getColumn(1).width = 30; worksheet.getColumn(2).width = 30; worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30; worksheet.getColumn(5).width = 30; worksheet.getColumn(6).width = 30;
    worksheet.getColumn(7).width = 30; worksheet.getColumn(8).width = 30; worksheet.getColumn(9).width = 30;
    worksheet.getColumn(10).width = 30; worksheet.getColumn(11).width = 30; worksheet.getColumn(12).width = 30;
    worksheet.getColumn(13).width = 30; worksheet.getColumn(14).width = 30; worksheet.getColumn(15).width = 30; 
    worksheet.getColumn(16).width = 30; worksheet.getColumn(17).width = 30; worksheet.getColumn(18).width = 30;
    worksheet.getColumn(19).width = 30; worksheet.getColumn(20).width = 30; worksheet.getColumn(21).width = 30;
    worksheet.getColumn(22).width = 30; worksheet.getColumn(23).width = 30; worksheet.getColumn(24).width = 30;
    worksheet.getColumn(25).width = 30; worksheet.getColumn(26).width = 30; worksheet.getColumn(27).width = 30;
    worksheet.getColumn(28).width = 30; worksheet.getColumn(29).width = 30; worksheet.getColumn(30).width = 30;
    worksheet.getColumn(31).width = 30; worksheet.getColumn(32).width = 30; worksheet.getColumn(33).width = 30;
    worksheet.getColumn(34).width = 30; worksheet.getColumn(35).width = 30; worksheet.getColumn(36).width = 30;

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `${option}.xlsx`);
    });
  }
}
