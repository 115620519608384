import { environment } from '../environments/environment';

declare var window;

const OAUTH = {
  AUTH_URL: environment.FEDERATION_AUTH_URL,
  RESPONSE_TYPE: 'code'
};

const DEFAULTS = {
  CLIENT_ID: environment.OAUTH_CLIENT_ID,
  OAUTH_REDIRECT_URI: environment.OAUTH_REDIRECT_URI,
  // SCOPE: ['profile1']
  SCOPE: [environment.SCOPE]
  // OAUTH_REDIRECT_URI: 'https://localhost:8080/',
  // SCOPE: ['profile1', 'openid']
};

export interface IOAuthOptions {
  CLIENT_ID?: string;
  SCOPE?: string[];
  OAUTH_REDIRECT_URI?: string;
}

export interface OAuth {
  oAuthLogin(url: string): Promise<any>;
}

class OAuthBrowser implements OAuth {
  options: IOAuthOptions;
  constructor(options: IOAuthOptions) {
    this.options = options;
  }

  public oAuthLogin(url: string): Promise<any> {
    return new Promise(function (resolve, reject) {
      console.log(url);
      window.location.href = url;
    });
  }
}


export class OAuthClient {
  private options: IOAuthOptions;
  private oAuthClient: OAuth;
  private url: string;

  constructor(options: IOAuthOptions = {}) {
    this.options = Util.defaults(options, DEFAULTS);
    if (!options.CLIENT_ID) {
      throw Error(`A client id must exist`);
    }
    /*if (environment.production) {
      this.options.OAUTH_REDIRECT_URI = 'https://ebiz-talend-poc-web.s3.amazonaws.com/index.html';
    } else {
      this.options.OAUTH_REDIRECT_URI = window.location.origin + window.location.pathname;
    }*/
    this.options.OAUTH_REDIRECT_URI = window.location.origin + '/index.html';

    this.oAuthClient = new OAuthBrowser(options);
    // tslint:disable-next-line: max-line-length
    this.url = `${OAUTH.AUTH_URL}?response_type=${OAUTH.RESPONSE_TYPE}&client_id=${options.CLIENT_ID}&redirect_uri=${options.OAUTH_REDIRECT_URI}&scope=${options.SCOPE.join(' ')}`;
  }

  //&response_mode=query

  authenticate(): Promise<any> {
    return this.oAuthClient.oAuthLogin(this.url);
  }

  getClientId(): string {
    return this.options.CLIENT_ID;
  }

  getRedirectUri(): string {
    return this.options.OAUTH_REDIRECT_URI;
  }
}

export const Util = {

  defaults(target: any, ...sources: object[]) {
    sources.forEach(source => {
      for (const prop in source) {
        if (!target.hasOwnProperty(prop)) {
          target[prop] = source[prop];
        }
      }
    });

    return target;
  }
};
