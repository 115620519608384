<div class="app-home">
  <div class="toolbar">
    <div class="toolbar-content">
      <a [routerLink]="['']">
        <img id="img" src="./assets/images/logo.svg" class="logo">
      </a>
      <div class="menu-actions" *ngIf="loggedIn && ebiz && eBizDev">
        <button class="clear-button main-menu-action" (click)="navigateHome()" style="border: 1px solid  rgba(101,172,30,0.4);border-right: none;">
          eBiz Home Page
        </button>
        <div *ngFor="let item of menu">
          <button class="clear-button main-menu-action" (click)="expand($event,item.link)">
            {{item.title}}<i class="material-icons expand">keyboard_arrow_right</i><i class="material-icons collapse">keyboard_arrow_down</i>
          </button>
          <div class="menu-child-action">
            <button class="clear-button subaction" *ngFor="let menuAction of item.children" (click)="navigate($event, menuAction.link)">{{menuAction.title}}</button>
          </div>
        </div>
      </div>
      <div class="menu-actions" *ngIf="loggedIn && !ebiz && eBillDev">
        <button class="clear-button main-menu-action" style="border: 1px solid  rgba(101,172,30,0.4);border-right: none;">
          Agency Billing
        </button>
      </div>
      <footer class="isNotScrolled">
        <span class="footer">© {{year}} BASF</span>
      </footer>
    </div>
    <div class="toolbar-panel"></div>
  </div>
  <div class="content">
    <header *ngIf="loggedIn && (eBizDev || eBillDev)">
      <button class="clear-button menu-button action-button">
        <i class="material-icons">menu</i>
      </button>
      <span style="float: right;">
        <button *ngIf="!welcome" class="profileButton" (click)="userProfileClicked()">
          <span id="user" class="material-icons userProfile">
            account_circle
          </span>
        </button>
      </span>
      <!-- <span style="float: right; color:red;"><marquee>"Your session will expire in another ":{{showTime}}</marquee> -->
        <span *ngIf="showcount" style="float: right; color:red; margin-right: 5px;">Your session will expire in {{showTime}}
       </span>
    </header>
    <nav aria-label="breadcrumb" *ngIf="loggedIn && (eBizDev || eBillDev)">
      <ol class="breadcrumb" >
        <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let last as isLast;" [ngClass]="{'active':last}"><a [routerLink]="breadcrumb.link">{{breadcrumb.name}}</a></li>
      </ol>
      <span *ngIf="userAccount" style="margin-top: -40px;float:right; background:white;z-index: 500;position: relative;">
        <ul style="margin-left: -40px;">
          <ol style="margin-left:-20px;padding-right: 20px;color: #65AC1E;">Welcome, {{userId}}</ol>
          <!-- <ol style="margin-left:-20px;padding-right: 20px;" *ngIf="roles.length>2"><a href="#">eBill</a></ol> -->
          <ol style="margin-left:-20px;padding-right: 20px;" *ngIf="ebiz && eBizDev && eBillDev"><a [routerLink]="['/eBill']" (click)="toggleEbiz()">eBill</a></ol>
          <ol style="margin-left:-20px;padding-right: 20px;" *ngIf="!ebiz && eBizDev && eBillDev"><a [routerLink]="['/']" (click)="toggleEbiz()">eBiz</a></ol>
        </ul>
      </span>
    </nav>
    <router-outlet></router-outlet>
  </div>
</div>