import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL } from './URL';
import { Service } from './service';
import { Observable } from 'rxjs';
import { AppState } from '../../app.state';
import { environment } from '../../../environments/environment';

@Injectable()
export class IdentityService extends Service {
  loggedIn = false;
  ebiz = false;
  ebill = false;

  constructor(protected state: AppState, http: HttpClient) {
    super(http, { 'Content-Type': 'application/json' });
  }

  getAccessToken(code: string, redirectUri: string): Observable<any> {
    const body = {code: code,redirectUri: environment.OAUTH_REDIRECT_URI,grantType: 'authorization_code'};
    return this.post(URL.IDENTITY_BASE_URL, body);
    // return this.post('', '');
  }

  getUserId(token): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + `${token}`
    });
    const body = {code: token, redirectUri: environment.OAUTH_REDIRECT_URI};
    return this.post(URL.BASE_URL + `/token/tokenInfo`, body, {headers: headers});
  }

  getRoles(token): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + `${token}`
    });
    const body = {code: token, redirectUri: environment.OAUTH_REDIRECT_URI};
    return this.post(URL.BASE_URL + `/token/userInfo`, body, {headers: headers});
  }

  
  logIn(loggedIn) {
    this.loggedIn = loggedIn;
  }

  eBiz() {
    this.ebiz = true;
  }

  eBill() {
    this.ebill = true;
  }

  eBizAuthhorized() {
    return this.ebiz;
  }

  eBillAuthhorized() {
    return this.ebill;
  }

}


