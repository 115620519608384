import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import '@angular/compiler';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './common/module/shared.module';
import { AppState } from './app.state';
import { IdentityService, SalesValidationService } from './common/service';
import { HomeComponent } from './home/home.component';
import { HttpClientModule, HttpClient} from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { MaintenanceComponent } from './maintenance/maintenance.component';
// import { CampaignComponent } from './maintenance/campaign/campaign.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { InventoryCurrentService } from './common/service/InventoryCurrentService';
import { DndDirective } from './dnd.directive';
// import { ProgressComponent } from './progress/progress.component';
// import { UploadFileComponent } from './upload-file/upload-file.component';
// import { UndeleteComponent } from './undelete/undelete.component';
// import { HistoryComponent } from './history/history.component';
// import { SalesUtilComponent } from './history/sales-util/sales-util.component';
// import { RecordDetailsComponent } from './record-details/record-details.component';
// import { EBillComponent } from './e-bill/e-bill.component';
// import { CurrentComponent } from './current/current.component';
// import { InventoryhistoryComponent } from './inventoryhistory/inventoryhistory.component';
// import { InventoryvalidationComponent } from './inventoryvalidation/inventoryvalidation.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DndDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  providers: [AppState, IdentityService, InventoryCurrentService, AppComponent, SalesValidationService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
